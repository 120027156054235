import React, { FC } from 'react';
import { graphql } from 'gatsby';

import useScreenRecognition from 'hooks/useScreenRecognition';

import { Layout, DangerouslySetInnerHtml } from 'layout';
import Breadcrumbs from 'components/Breadcrumbs';
import Timeline from 'components/Timeline';

import { WhyDurexComponentProps } from './models';
import './WhyDurexPageMain.scss';
import './WhyDurexPageMainExtends.scss';

const WhyDurexPage: FC<WhyDurexComponentProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    umbracoWhyDurex: {
      url,
      title,
      timeline,
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
      defaultCompositions,
    },
  },
}) => {
  const { isMobile } = useScreenRecognition();

  return (
    <Layout
      {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
      seo={{
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaDescription,
        seoExternalHreflangs,
      }}
      className="why-durex-page"
    >
      <div className="why-durex-page__wrapper">
        {!isMobile ? <Breadcrumbs url={url} light className="why-durex-page__breadcrumbs" /> : null}
        <DangerouslySetInnerHtml className="why-durex-page__title" html={title} />
        {timeline.map(({ properties: timelineProperties }, timelineIndex) => (
          <Timeline
            // eslint-disable-next-line react/no-array-index-key
            key={`history-page-${timelineIndex}`}
            events={timelineProperties.events?.map(({ properties: eventProperties }) => ({
              ...eventProperties,
              image: eventProperties.localImage,
            }))}
            config={{
              showImagesOnMobile: timelineProperties.isImagesOnMobileVisible,
              firstImagePosition: timelineProperties.isFirstImageOnRight ? 'right' : 'left',
            }}
            className="why-durex-page__timeline"
          />
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query WhyDurexPage($url: String = "", $lang: String) {
    umbracoWhyDurex: umbracoWhyDurex(url: { eq: $url }, lang: { eq: $lang }) {
      url
      title
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      timeline {
        properties {
          id
          isFirstImageOnRight
          isImagesOnMobileVisible
          events {
            properties {
              id
              text
              textStyle
              title
              localImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              imageAlt
              imageStyle
            }
          }
        }
      }
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;

export default WhyDurexPage;
